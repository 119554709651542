import styled from 'styled-components'
import { Autoplay, Keyboard, Mousewheel } from 'swiper'
import 'swiper/css'
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react'
import type { SinglePanelFieldsFragment } from '@nordic-web/gql'
import { NavigationDots, Stack } from '@nordic-web/ui-components'
import { HeroRatioContainer } from '@/components/hero/hero'
import { SinglePanel } from '@/features/panel-factory/components/single-panel'
import { useSwiperActiveIndex, useSwiperSlideCount } from '@/features/video/rich-player/endscreen.hooks'
import { useIsBelowTablet } from '@/hooks/use-breakpoint'

type MultiSinglePanel = {
  panels: SinglePanelFieldsFragment[]
}

type SlideProps = {
  panel: SinglePanelFieldsFragment
  index: number
}

function SwiperNavigationNavigation() {
  const swiper = useSwiper()
  const activeIndex = useSwiperActiveIndex()
  const count = useSwiperSlideCount()
  const isMobile = useIsBelowTablet()

  return (
    <Stack slot="container-end" nwAlignItems="center">
      <NavigationDots
        activeIndex={activeIndex}
        count={count}
        onPrevious={() => swiper.slidePrev()}
        onNext={() => swiper.slideNext()}
        hideButtons={isMobile}
        onDotClick={(index) => swiper.slideToLoop(index)}
      />
    </Stack>
  )
}

function Slide({ panel, index }: SlideProps) {
  const swiper = useSwiper()
  const { isActive } = useSwiperSlide()

  function onTrailerPlay() {
    swiper.autoplay.stop()
  }

  function onTrailerEnd() {
    setTimeout(() => {
      if (isActive) {
        swiper.slideNext()
        swiper.autoplay.start()
      }
    }, 4000)
  }

  return (
    <SinglePanel
      index={index}
      panel={panel}
      hideCdpButton={false}
      onTrailerEnd={onTrailerEnd}
      onTrailerPlay={onTrailerPlay}
    />
  )
}

export function MultiSinglePanel({ panels }: MultiSinglePanel) {
  return (
    // This ratio container is needed to let the div have a size before the swiper is initialized, without this the
    // scrollX position isnt set correctly when navigating back in the browser.
    <HeroRatioContainer>
      <MultiSwiper
        resistanceRatio={0}
        spaceBetween={0}
        slidesPerView={1}
        loop
        watchSlidesProgress
        modules={[Autoplay, Keyboard, Mousewheel]}
        autoplay={{ delay: 6000 }}
        keyboard={{ enabled: true }}
        mousewheel={{
          forceToAxis: true,
          sensitivity: 0.5,
          thresholdDelta: 10,
        }}
        threshold={10}
      >
        {panels.map((panel, index) => (
          <SwiperSlide key={panel.id}>
            <Slide panel={panel} index={index} />
          </SwiperSlide>
        ))}
        <SwiperNavigationNavigation />
      </MultiSwiper>
    </HeroRatioContainer>
  )
}

const MultiSwiper = styled(Swiper)({
  overflow: 'visible',
  zIndex: 0,
})
